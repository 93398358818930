<template>
  <div>
    <the-app-bar />
    <remove-account />
    <Footer />
  </div>
</template>



<script>
import RemoveAccount from "../../../components/client/RemoveAccount.vue";
import Footer from "../../../components/footer.vue";
import TheAppBar from "../../../components/NavBar/TheAppBar.vue";
export default {
  components: { TheAppBar, Footer, RemoveAccount }
};
</script>
