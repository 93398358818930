<template>
  <div class="font-montserrat top">
    <div class="row py-24 px-12 oonpay-bg">
      <div class="col-md-12">
        <h4 class="font-semibold text-center text-oonpaytext text-3xl">
          Remove Account
        </h4>
      </div>
    </div>

    <div class="container">
      <div class="row justify-content-center">
        <div v-if="sms_sent" class="col-md-4 py-5 text-center border-2 my-5">
          <i class="fa-solid fa-2x fa-message"></i>
          <h5>Authentication code</h5>
          <div class="form-group">
            <input
              type="text"
              v-model="OTP"
              placeholder="OTP code"
              class="form-control"
            />
          </div>

          <div class="form-group text-left">
            <label for="">Enter Reason For Account Deletion</label>
            <textarea
              name=""
              v-model="reason"
              class="form-control"
              rows="4"
            ></textarea>
          </div>

          <p>
            We just sent you a message via SMS with your authentication code.
            Enter the code in the form above to verify your identity.
          </p>

          <p class="text-danger">
            NB: Please be aware that this action is irreversible. Once
            completed, it cannot be undone.
          </p>

          <button
            @click="handleSubmit"
            type="button"
            class="btn btn-primary btn-block"
          >
            <b-spinner v-if="loading" type="grow"></b-spinner>
            <span v-else>Submit</span>
          </button>
        </div>
        <div v-else class="col-md-4 py-5 text-center border-2 my-5">
          <i class="fa-solid fa-2x fa-message"></i>
          <h5>Authentication code</h5>
          <p>
            When your phone is ready, click the button below to receive a code
            via SMS.
          </p>

          <button
            @click="SendSMS"
            type="button"
            class="btn btn-primary btn-block"
          >
            <b-spinner v-if="loading" type="grow"></b-spinner>
            <span v-else>Send SMS</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-mixed-spaces-and-tabs */

export default {
  data() {
    return {
      loading: false,
      OTP: "",
      reason: "",
      sms_sent: false,
      account_info: JSON.parse(localStorage.getItem("account_info"))
    };
  },

  components: {},

  computed: {
    isDisbaled() {
      return this.verify_code.length > 0 && this.password.length > 0;
    }
  },

  methods: {
    SendSMS() {
      const payload = {
        OTPReasonId: 99,
        UserTypeId: 1,
        PhoneNumber: this.account_info.phoneNumber,
        Email: "",
        CallCode: this.account_info.countryCallCode,
        OTP: ""
      };

      this.loading = true;
      this.$store
        .dispatch("default/sendOTP", payload)
        .then((res) => {
          if (res.status == 200) {
            this.loading = false;
            this.sms_sent = true;
          }
        })
        .catch((err) => {
          this.loading = false;
          this.$toasted.error(err.response.data, {
            duration: 5000
          });
        });
    },
    handleSubmit() {
      const payload = {
        otpreasonid: 99,
        usertypeId: 1,
        phonenumber: this.account_info.phoneNumber,
        callcode: this.account_info.countryCallCode,
        email: "",
        otp: this.OTP,
        reason: this.reason
      };
      // Add your submit logic here
      this.loading = true;
      this.$store
        .dispatch("default/RequestAccountDel", payload)
        .then((res) => {
          if (res.status == 200) {
            this.loading = false;
            this.$swal({
              icon: "success",
              title: "Success",
              text: "Request has been sent successfully"
            });
            localStorage.clear();
            location.href = "/";
          }
        })
        .catch((err) => {
          this.loading = false;
          this.$toasted.error(err.response.data, {
            duration: 5000
          });
        });
    }
  }
};
</script>

<style scoped>
.oonpay-bg {
  background: #0c4369;
}

img {
  width: 100%; /* You can set the dimensions to whatever you want */
  height: 200px;
  object-fit: cover;
}

@media screen and (max-width: 720px) {
  .oonpay-row {
    flex-direction: column-reverse;
    margin: 5rem 0;
  }
  .top {
    margin-top: -80px;
  }
}
</style>
